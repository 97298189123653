<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div id="app">
    <myPage />
    <!-- <Form /> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Form from './components/Form.vue';
import myPage from './components/myPage.vue';
import { ref } from 'vue';
  
export default {
  setup() {
    const picked = ref(null);
    return {
      picked
    }
  },
  name: 'App',
  components: {
    // HelloWorld,
    // Form,
    myPage,
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
