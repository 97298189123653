<template>
    <div class="container">
      <img src="@/assets/pic.jpg" alt="background image" class="background-image">

      <section class="content">
        <h2>中医养生名师大讲堂</h2>
        <div class="features">
          <span>理论+实操结合</span>
          <span>老中医临床精粹</span>
          <span>0基础手把手教学</span>
        </div>
      </section>
  
      <section class="forms">
        <input style="font-size: 200%; text-align: center;" v-model="phoneNumber" type="text" placeholder="请输入手机号">
      </section>
      <section class="submit-button">
        <button style="width: 50%; height: 30%; font-size: 120%;" @click="submitForm">立即提交领课</button>
      </section>
      <!-- <section class="radio-section">
        <h3>请选择性别：</h3>
        <label>
        <input type="radio" value="male" v-model="gender">
        男
        </label>
        <label>
        <input type="radio" value="female" v-model="gender">
        女
        </label>
         <p>选中的性别是：{{ gender === 'male' ? '男' : '女' }}</p> 
      </section> -->

      <!-- <section class="form-section">
        <input v-model="phoneNumber" type="text" placeholder="请输入手机号">
        <button @click="submitForm">立即提交领课</button>
      </section> -->
  
      <!-- <footer class="footer">
        <div>6天养生课</div>
        <button>立即领取</button>
      </footer> -->
    </div>
  </template>
  
  <script>
//   import axios from 'axios'

  export default {
    data() {
      return {
        phoneNumber: '',
        gender: '',
      }
    },
    methods: {
      submitForm() {
        // 在这里处理表单提交逻辑
        console.log('性别：',this.gender);
        console.log('手机号:', this.phoneNumber);
        const externalUrl = "http://www.baidu.com";
        window.location.href = externalUrl;
        // axios({
        //     methods: 'post',
        //     url: '',
        //     data: {

        //     }
        // })
        // .then(function (response) {  
        // console.log(response);  
        // })  
        // .catch(function (error) {  
        // console.error(error);  
        // });
      }
    }
  }
  </script>
  
  <style scoped>
   .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f8f8f8;
    @media screen and (max-width: 768px) {
        display: block;
    }
  }

  .backgr.background-image {
    height: auto;
    display: block;
    @media screen and (max-width: 768px) {
        width: 20px;
        max-height: 30px;;
        display: block;
    }
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  
  .content {
    position: absolute;
    height: auto;
    text-align: center;
    margin: 20px 0;
  }
  
  .features span {
    display: inline-block;
    margin: 0 10px;
  }
  
  .forms {
    position: absolute;
    width: 50%;
    text-align: center;
    bottom: 30%;
  }

  .submit-button {
    position: absolute;
    width: 50%;
    height: 20%;
    text-align: center;
    bottom: 5%;
  }

  .submit-button button {
    padding: 10px 20px;
    background-color: #b22222;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  .radio-section {
    background-color: #555353; 
    color: rgb(238, 123, 123);
    position: absolute;
    width: 20%;
    text-align: center;
    bottom: 31%;
  }

  .form-section {
    position: absolute;
    width: 50%;
    text-align: center;
    bottom: 20%;
  }
  
  .form-section input {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .form-section button {
    padding: 10px 20px;
    background-color: #b22222;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  
  .footer button {
    padding: 10px 20px;
    background-color: #ffa500;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  .label {
  margin-right: 10px;
  } 
  </style>
  